import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import { IPageFAQComponentProps } from '../../../lib/types/components';
import { dynamicCssSize, getId } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from "../CustomImage/CustomImageComponent";

const PageFAQComponentV2 = (props: IPageFAQComponentProps) => {

    const {
        pageFAQData
    } = props;

    return (
        pageFAQData && Object.keys(pageFAQData).length?
            <H2TrapeziumBG uniqueContainerId={"pageFaq_container"}>
                <style jsx>{`
                  .pageFAQParentContainerV2 {
                    font-family: var(--font-primary);
                    padding: 18px 0px 5px;
                    color: white;
                    background-color: #392564;
                    position: relative;
                  }

                  .headingParent {
                    position: relative;
                    display: flex;
                    justify-content: center;
                  }

                  .heading {
                    font-size: ${dynamicCssSize(60, 16)};
                    font-weight: 900;
                    font-style: italic;
                    max-width: 65vw;
                    text-align: center;
                  }

                  .faqParentContainer {
                    padding: 50px 0px;
                  }

                  .faqContainer {
                    position: relative;
                    margin-bottom: 20px;
                    border: 2px solid #ffffff;
                    border-radius: 10px;
                    transition: 300ms;
                  }

                  .faqContainer:has(.input:checked) {
                    background: #ffffff;
                  }

                  .input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    visibility: hidden;
                  }

                  .h3 {
                    color: #ffffff;
                    padding: 1vw 3vw;
                    position: relative;
                    font-size: ${dynamicCssSize(30, 12)};
                    font-weight: 500;
                    margin: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    transition: all 300ms;
                  }

                  .label {
                    cursor: pointer;
                    position: relative;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 0;
                  }

                  .label span {
                    font-size: ${dynamicCssSize(48, 11)};
                    font-weight: 800;
                    color: #ffffff;
                    min-width: 10px;
                    margin-right: 2vw;
                  }

                  .para {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 300ms;
                  }

                  .para p {
                    font-size: ${dynamicCssSize(24, 12)};
                    font-weight: 100;
                    color: #000;
                    padding: 0 7vw 1vw;
                  }
                  .para ul {
                    margin-left: 100px;
                  }

                  .para li {
                    font-size: 20px;
                    font-weight: 300;
                    color: #000;
                    padding: 0% 2% 1%;
                  }
                  .h3::after {
                    content: "";
                    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/expended-plus_v2.svg") no-repeat;
                    background-size: contain;
                    width: ${dynamicCssSize(45, 12)};
                    height: 100%;
                    position: absolute;
                    right: 1%;
                    top: 30%;
                    opacity: 0.7;
                  }

                  .input:checked ~ label h3 {
                    color: #000000;
                    font-weight: 600;
                  }

                  .input:checked ~ label span {
                    color: #000000;
                  }

                  .input:checked ~ label h3::after {
                    content: "";
                    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/collapsed-minus_v2.svg") no-repeat;
                    background-size: contain;
                    filter: invert(0%);
                    width: ${dynamicCssSize(45, 12)};
                    position: absolute;
                    right: 1%;
                    top: 50%;
                    opacity: 0.7;
                  }

                  .faqTitle {
                    margin: 0 18px 0 0;
                  }

                  .input:checked ~ div.para {
                    max-height: 100vh;
                  }

                  @media screen and (max-width: 767px) {
                    .faqParentContainer {
                      padding: 16px;
                    }

                    .faqContainer {
                      margin-bottom: 10px;
                    }

                    .label span {
                      font-weight: 600;
                    }

                    .input:checked ~ label h3::after {
                      top: 50%;
                      right: 2%;
                    }

                    .input ~ label h3::after {
                      top: 20%;
                      right: 2%;
                    }
                    
                    .para p{
                      padding: 0 7vw;
                    }
                    .para ul {
                      margin-left: 0;
                    }

                    .para li{
                      font-size: 12px;
                    }
                    .input:checked ~ div.para {
                      height: fit-content;
                    }
                  }

                  @media screen and (max-width: 576px) {
                    .faqContainer {
                      position: relative;
                      margin-bottom: 4px;
                      border: 1px solid rgba(255, 255, 255, 0.7);
                      border-radius: 2px;
                    }
                  }
                `}
                </style>
                <div className="container-fluid pageFAQParentContainerV2" id="id_faq">
                    <CustomImageComponent
                        src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/contours_bg.webp"}
                        layout={"fill"}
                        alt={"content image"}
                        objectFit={"cover"}/>
                    <div className='container'>
                        <div className={"headingParent"}>
                            <h2 className={"heading"} id={getId(pageFAQData.heading)}>{pageFAQData.heading}</h2>
                        </div>
                        <div className='row faqParentContainer'>
                            <div className='col'>
                                <div className={"page-faqs"}>
                                    {
                                        pageFAQData.faqs.map((faq, i) => {
                                            return (
                                                <div className="faqContainer" key={"pageFAQData.faqs_" + i}>
                                                    <input className="input" type="checkbox"
                                                           id={`${pageFAQData.heading}-faq-${i + 1}`}/>
                                                    <label className="label"
                                                           htmlFor={`${pageFAQData.heading}-faq-${i + 1}`}>
                                                        <h3 className="h3">
                                                            <span>{i + 1}</span>
                                                            <p className="faqTitle">{faq.title}</p>
                                                        </h3>
                                                    </label>
                                                    <div className="para">
                                                        {
                                                            faq.paragraphs.map((item, index) => {
                                                                return (
                                                                    <p key={index}>
                                                                        {item}
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                        {
                                                          faq?.points && <ul>
                                                            {
                                                              faq.points.map((item, index) => {
                                                                return (
                                                                  <li key={"faq_points_" + index}>
                                                                    {item}
                                                                  </li>
                                                                )
                                                              })
                                                            }
                                                          </ul>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG> : <></>
    )
}

export default PageFAQComponentV2;